@import '~assets/scss/variables.scss';

.currentVisitWrapper {
  background: radial-gradient(
    23.41% 82.14% at 14.73% 0%,
    #ffe6b6 0%,
    #ffe88c 73.56%
  );
  border-radius: 4px;
}

.subtitle {
  line-height: 16px !important;
  color: map-get($colors, 'shade_dark_grey') !important;
}

.formTitle {
  color: map-get($colors, 'shade_medium_grey') !important;
}

.boldText {
  font-weight: bold !important;
}

.normalText {
  font-weight: 500 !important;
  color: map-get($colors, 'shade_grey_blue') !important;
}

.generalInfo {
  color: map-get($colors, 'general_blue') !important;
}

.statusChip {
  width: fit-content;
  span {
    font-weight: 700 !important;
    color: map-get($colors, 'shade_grey_blue') !important;
  }
}
