@import "../../../../assets/scss/variables";

.logInContentContainer {
  margin-bottom: 60px;
  // max-width: 1200px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  .signInForm {
    max-width: 500px;
  }
  .leftPane {
    // padding-right: 60px;
    // padding-left: 60px;
  }
  .signInTitle {
    font-weight: 600;
    font-size: 40px;
    margin-bottom: 32px;
    margin-top: 20px;
  }
  .signInSubtitle {
    color: map-get($colors, grey_600);
    margin-top: 15px;
  }
  .textAlignLeft {
    text-align: left;
  }
  .link {
    margin-left: 5px;
    margin-right: 5px;
    color: map-get($colors, wm-blue-color);
  }

  .signInButton {
    text-align: left;
    align-self: center;
    margin-top: 20px;
    text-transform: none;
  }
  .emailBox {
    background-color: map-get($colors, grey_100);
  }
  .forgotPwWraper {
    text-align: right;

    a {
      color: map-get($colors, wm-blue-color);
    }
  }
  .btnStyle {
    height: 36px;
    padding-top: 2px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 2px;
    text-transform: uppercase;
    font-size: 16px;
    color: white;
    background-color: map-get($colors, wm-blue-color);

    .btnLabel {
      text-transform: uppercase,
    }
  }
  .termsPrivacyText {
    text-align: left;
    color: map-get($colors, wm-blue-color);
    font-size: 12px;
    margin-top: 30px;
  }
  .rightPane {
    // padding-left: 60px;
    border-width: 0px;
    // border-left-width: 1px;
    border-style: solid;
    border-color: map-get($colors, wm-blue-color);
    height: 100%;
    overflow: hidden;
  }
  .imageContainer {
    position: relative;
    height: 100%;
  }
  .whiteLogo {
    max-width: 128px;
    position: absolute;
    top: 20px;
    left: 25px;
    z-index: 10;
  }
  .cleanerImage {
    width: auto;
    height: 100%;
  }
}
.appContainer {
  height: 100vh;
}
.imageStyle, .formContainer {
  height: 100%;
  width: 100%;
}
.w_50 {
  width: 75% !important;
}
.headerLogo {
  padding-bottom: 32px;
}
.teamDialogTitleText {
  color: rgba(0, 0, 0, 0.5) !important;
  font-weight: 600 !important;
}
@media only screen and (max-width: 767px) {
  .w_50 {
    width: 100% !important;
  }
  .signInTitle {
    line-height: unset !important;
  }
  .leftPane, .imageContainer, .rightPane {
    height: unset !important;
  }
  .poweredBy {
    margin-top: 20px;
  }
}