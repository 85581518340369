@import "../../assets/scss/variables";
.greeting-page {
  padding-bottom: 50px;
  text-align: center;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;

  .header-logo {
    position: absolute;
    width: 150px;
    left: 40px;
    top: 40px;

    .logo {
      width: 100%;
    }
  }

  .page-title {
    .congratulation-text {
      text-transform: uppercase;
      padding-top: 50px;
      font-size: 25px;
      padding-bottom: 10px;
      font-weight: 500;
      color: map-get($colors, wm-blue-color);
    }

    .discription {
      color: map-get($colors, wm-blue-color);
    }
  }

  .user-exps {
    padding: 40px 0 0;

    .user-exp {
      width: 120px;
      height: 120px;
    }

    .description {
      color: map-get($colors, wm-blue-color);
      padding-top: 10px;
    }

  }
  .job-steps {
    padding-top: 60px;

    .progress-indicator {
      margin: 0;
      padding: 0;
      font-size: 80%;
      text-transform: uppercase;
      margin-bottom: 10px;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;

      li.step {
        -ms-flex: 1;
        -webkit-flex: 1;
        -moz-flex: 1;
        flex: 1;
        width: auto;
        list-style: none;
        text-align: center;
        margin: 0;
        display: inline-block;
        color: map-get($colors, wm-blue-color );
        font-weight: 500;
        position: relative;
        font-size: 18px;
        text-transform: capitalize;

        &::before {
          content: '';
          position: absolute;
          top: 58px;
          left: 0;
          width: 100%;
          height: 4px;
          background: map-get($colors, grey);
          z-index: -1;
        }

        &:first-child {
          &::before {
            left: 50%;
          }
        }
        &:last-child {
          &::before {
            width: 50%;
          }
        }

        .assignment-icon, .completing-icon, .scheduling-icon, .getting-paid-icon {
          border-radius: 1000px;
          width: 100px;
          height: 100px;
          background-color: #ddd;
          display: block;
          margin: 0 auto 0.5em auto;
          background-color: map-get($colors, grey);
          position: relative;
          padding: 10px;
          box-sizing: border-box;

          img {
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 50px;
          }
        }

        .assignment-icon {
          background-color: map-get($colors, wm-blue-color);
        }

        .text {
          padding-top: 20px;
        }

      }
    }
  }

  .start-the-tour {
    padding-bottom: 10px;
    padding-top: 50px;

    .redirect-to-tour {
      text-decoration: none;

      .start-the-tour-btn {
        background: map-get($colors, wm-blue-color);
        color: white;
        font-size: 18px;
        font-weight: 500;
        text-transform: none;
        font-family: $font-family;
        padding: 0 20px;
        padding: 5px 50px;
        border-radius: 5px;
      }

    }
  }
  .skip-the-tour {
    color: map-get($colors, wm-blue-color);
  }

}