@import '~assets/scss/variables.scss';

.title {
  padding-bottom: 0px !important;
  h2 {
    font-size: 24px;
  }
}
.message {
  font-size: 16px;
}

.confirm-button {
  background-color: #FFCC09 !important;
}

.modal-footer { 
  padding-top: 0px !important;
  border: none;
}

.review-button {
  height: 40px;
  border-radius: 8px !important;
}

.start-review-button {
  background-color: #FFCC09 !important;
}

.end-review-button {
  background-color: #FFCC09 !important;
  display: flex !important;
  justify-content: space-between !important;
}

.open-review-button {
  background-color: map-get($colors, 'shade_light_grey') !important;
}

.review-menu {
  border: 0.97px solid #F0F0F0;
  box-shadow: 0px 3.8658535480499268px 3.8658535480499268px 0px #00000040;
}

.review-menu-item {
  padding: 8px 16px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}
