// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

// Font path is used to include ionicons,
// roboto, and noto sans fonts
$image-path: "assets/images";

// Named Color Variables
// --------------------------------------------------
// Named colors makes it easy to reuse colors on various components.
// It's highly recommended to change the default colors
// to match your app's branding. Ionic uses a Sass map of
// colors so you can add, rename and remove colors as needed.
// The "primary" color is the only required color in the map.

$colors: (
  primary:    #488aff,
  secondary:  #32db64,
  danger:     #f53d3d,
  light:      #f4f4f4,
  dark:       #222,
  grey:       #DFE3E8,
  lighter-grey: 'rgba(0, 0, 0, 0.33)',
  wm-blue-color:#043D8B,
  wm-pink-color: #ec008b,
  section-bg:  #F0F0F0,
  bg_late_visit: #e2f1fa,
  color_text_late_visit: #276992,
  bg_unassigned: #fdefde,
  color_text_unassigned: #965d16,
  bg_on_standby: #fde6e0,
  color_text_on_standby: #9b3921,
  bg_on_the_way: #653838,
  color_text_on_the_way: #b98578,
  bg_arrived: #eceae9,
  color_text_arrived: #6b7eda,
  bg_started: #022aec,
  color_text_started: #ffffff,
  bg_completed: #ce5f00,
  color_text_completed: #ffffff,
  bg_abandoned: #f30324,
  color_text_abandoned: #ffffff,
  bg_archived: #000000,
  color_text_archived: #276992,
  bg_unscheduled: #33e65a,
  color_text_unscheduled: #1f03e6,
  grey_50: #FAFAFA,
  grey_100: #F5F5F5,
  grey_200: #EEEEEE,
  grey_300: #E0E0E0,
  grey_400: #BDBDBD,
  grey_500: #9E9E9E,
  grey_600: #757575,
  grey_620: #707070,
  grey_700: #616161,
  grey_800: #424242,
  grey_900: #212121,
  rry_blue: #2E4F9B,
  light_grey: #e2e2e2,
  dark_color: rgba(0, 0, 0, 0.25),
  primary_pink: #EB008B,
  primary_blue: #2D4E9D,
  black_pure: #0D0D0D,
  black_dark: #666666,
  black_medium: #C4C4C4,
  black_light: #E2E2E2,
  black_ultra_light: #F2F2F2,
  dash_board_background: #3A3C4D,
  primary_yellow: #F9C825,
  workforce_background: #282A3A,
  less_black: #2D303D,
  why_wm: #F1F4F8,
  button_hover: #B9B9B9,
  positive_green: #30C17C,
  active_badge: #E8FDF1,
  shade_dark_grey: #4C4C4C,
  shade_medium_grey: #A2A2A2,
  general_blue: #0075FE,
  general_red: #F90042,
  general_green: #1EB785,
  light_yellow: #FFF4D0,
  shade_grey_blue: #3A4457,
  pending_color: #FFCF26,
  in_progress_color: #26E5FF,
  completed_color: #2697FF,
  cancelled_color: #EE2727,
  side_bar_color: #F2F3F7,
  shade_black: #101010,
  shade_light_grey: #EAEAEA,
  dark_grey: #4c4c4c,
  order_id: #606060
);

$status: (
  green: #8AC788,
  blue: #043D8B,
  general_color: #212121,
  lateVisit_borderColor: #8952FF,
  lateVisit_backgroundColor: #EFE8FF,
  unassigned_borderColor: #FF770B,
  unassigned_backgroundColor: #FEEEE1,
  onStandby_borderColor: #F4E29D,
  onStandby_backgroundColor: #F3EED9,
  onTheWay_borderColor: #94BFFF,
  onTheWay_backgroundColor: #D6E3F6,
  arrived_borderColor: #E6A598,
  arrived_backgroundColor: #FBE6E2,
  started_borderColor: #5CBAB4,
  started_backgroundColor: #C9E8E6,
  completed_borderColor: #3BB673,
  completed_backgroundColor: #CFEEE5,
  cancel_backgroundColor: #FFDFDF,
  cancelled_backgroundColor: #E6E6E6,
  cancelled_borderColor: #797979
);

$font-family: 'DM Sans';
$font-Lato: 'Lato';

:root {}