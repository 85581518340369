@import '~assets/scss/variables.scss';

.card {
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.06) !important;
  height: 240px;
  overflow-y: hidden;
  position: relative;
  z-index: 1;
}

.borderBottom {
  border-bottom: 1px solid map-get($colors, 'section-bg');
}
